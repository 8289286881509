<template>
  <div>
    <div class="question" v-if="this.question">
      <Single
        :question="this.question"
        v-if="this.type === 1 || this.type === '1'"
        :type="this.type"
      ></Single>
      <Multiple
        :question="this.question"
        v-if="this.type === 2 || this.type === '2'"
        :type="this.type"
      ></Multiple>
      <Subjective
        :question="this.question"
        v-if="this.type === 3 || this.type === '3'"
        :type="this.type"
      ></Subjective>
    </div>
  </div>
</template>

<script>

import Single from '@/components/course/Single'
import Multiple from '@/components/course/Multiple'
import Subjective from '@/components/course/Subjective'

export default {
  components: { Single: Single, Multiple: Multiple, Subjective: Subjective },
  props: ['question', 'type']
}
</script>

<style lang="scss" scoped>
.question {
  padding: 1.389vw;
  background-color: rgb(243, 233, 217);
}
</style>
