<template>
  <div>
    <div class="question" v-if="this.question">
      <div class="question-type">
        多选题 ID:{{ this.question.id }} 难度：{{ this.question.ddf }}
      </div>
      <div>
        <div v-html="this.question.title" class="question-title"></div>
        <div v-if="this.question.titlepic">
          <img src="this.question.titlepic" width="80%" />
        </div>
      </div>
      <van-checkbox-group
        v-model="result"
        ref="checkboxGroup"
        :disabled="disabled"
      >
        <van-checkbox
          name="1"
          class="question-item"
          v-if="this.question.a !== '-' || this.question.a === ''"
          shape="square"
          @click="choice('1')"
          >{{ this.question.a }}
          <span
            v-if="this.question.acorrect === 1 && this.answerShow"
            class="correct"
          >
            本题答案</span
          >
          <span
            v-if="
              this.answerShow &&
              this.question.acorrect === 1 &&
              this.mychoiceA === '1'
            "
            class="right"
          >
            √ 选择正确</span
          >
          <span
            v-else-if="
              this.answerShow &&
              this.question.acorrect === 1 &&
              this.mychoiceA === ''
            "
            class="wrong"
          >
            × 未选择</span
          >
          <span
            v-else-if="
              this.answerShow &&
              this.question.acorrect !== 1 &&
              this.mychoiceA === '1'
            "
            class="wrong"
          >
            × 选择错误</span
          >
        </van-checkbox>
        <van-checkbox
          name="2"
          class="question-item"
          v-if="this.question.b !== '-' || this.question.b === ''"
          shape="square"
          @click="choice('2')"
          >{{ this.question.b }}
          <span
            v-if="this.question.bcorrect === 1 && this.answerShow"
            class="correct"
          >
            本题答案</span
          >
          <span
            v-if="
              this.answerShow &&
              this.question.bcorrect === 1 &&
              this.mychoiceB === '1'
            "
            class="right"
          >
            √ 选择正确</span
          >
          <span
            v-else-if="
              this.answerShow &&
              this.question.bcorrect === 1 &&
              this.mychoiceB === ''
            "
            class="wrong"
          >
            × 未选择</span
          >
          <span
            v-else-if="
              this.answerShow &&
              this.question.bcorrect !== 1 &&
              this.mychoiceB === '1'
            "
            class="wrong"
          >
            × 选择错误</span
          >
        </van-checkbox>
        <van-checkbox
          name="3"
          class="question-item"
          v-if="this.question.c !== '-' || this.question.c === ''"
          shape="square"
          @click="choice('3')"
          >{{ this.question.c }}
          <span
            v-if="this.question.ccorrect === 1 && this.answerShow"
            class="correct"
          >
            本题答案</span
          >
          <span
            v-if="
              this.answerShow &&
              this.question.ccorrect === 1 &&
              this.mychoiceC === '1'
            "
            class="right"
          >
            √ 选择正确</span
          >
          <span
            v-else-if="
              this.answerShow &&
              this.question.ccorrect === 1 &&
              this.mychoiceC === ''
            "
            class="wrong"
          >
            × 未选择</span
          >
          <span
            v-else-if="
              this.answerShow &&
              this.question.ccorrect !== 1 &&
              this.mychoiceC === '1'
            "
            class="wrong"
          >
            × 选择错误</span
          >
        </van-checkbox>
        <van-checkbox
          name="4"
          class="question-item"
          v-if="this.question.d !== '-' || this.question.d === ''"
          shape="square"
          @click="choice('4')"
          >{{ this.question.d }}
          <span
            v-if="this.question.dcorrect === 1 && this.answerShow"
            class="correct"
          >
            本题答案</span
          >
          <span
            v-if="
              this.answerShow &&
              this.question.dcorrect === 1 &&
              this.mychoiceD === '1'
            "
            class="right"
          >
            √ 选择正确</span
          >
          <span
            v-else-if="
              this.answerShow &&
              this.question.dcorrect === 1 &&
              this.mychoiceD === ''
            "
            class="wrong"
          >
            × 未选择</span
          >
          <span
            v-else-if="
              this.answerShow &&
              this.question.dcorrect !== 1 &&
              this.mychoiceD === '1'
            "
            class="wrong"
          >
            × 选择错误</span
          >
        </van-checkbox>
        <van-checkbox
          name="5"
          class="question-item"
          v-if="this.question.e !== '-' || this.question.e === ''"
          shape="square"
          @click="choice('5')"
          >{{ this.question.e }}
          <span
            v-if="this.question.ecorrect === 1 && this.answerShow"
            class="correct"
          >
            本题答案</span
          >
          <span
            v-if="
              this.answerShow &&
              this.question.ecorrect === 1 &&
              this.mychoiceE === '1'
            "
            class="right"
          >
            √ 选择正确</span
          >
          <span
            v-else-if="
              this.answerShow &&
              this.question.ecorrect === 1 &&
              this.mychoiceE === ''
            "
            class="wrong"
          >
            × 未选择</span
          >
          <span
            v-else-if="
              this.answerShow &&
              this.question.ecorrect !== 1 &&
              this.mychoiceE === '1'
            "
            class="wrong"
          >
            × 选择错误</span
          >
        </van-checkbox>
      </van-checkbox-group>

      <div v-if="this.answerShow" class="answer">
        <div class="answer-title">试题解析</div>
        <div class="answer-content">
          {{ this.question.answer }}
          <div v-if="this.question.answerpic">
            <img src="this.question.answerpic" width="80%" />
          </div>
        </div>
      </div>
      <br />
      <van-button
        type="info"
        size="small"
        block
        @click="submit"
        v-if="!this.answerShow"
        >提交</van-button
      >
      <van-button type="info" size="small" block @click="next" v-else
        >下一题</van-button
      >
    </div>
    <div v-else class="wait">系统正在搜索试题......</div>
  </div>
</template>

<script>
import Vue from 'vue'
const api = new Vue()
export default {
  data () {
    return {
      result: [],
      mychoiceA: '',
      mychoiceB: '',
      mychoiceC: '',
      mychoiceD: '',
      mychoiceE: '',
      successive: 0,
      answerShow: false,
      disabled: false,
      correct: true
    }
  },
  props: ['question', 'type'],
  methods: {
    choice (e) {
      //   alert(e)
      if (!this.answerShow) {
        if (e === '1') {
          this.mychoiceA = this.mychoiceA ? '' : '1'
        }
        if (e === '2') {
          this.mychoiceB = this.mychoiceB ? '' : '1'
        }
        if (e === '3') {
          this.mychoiceC = this.mychoiceC ? '' : '1'
        }
        if (e === '4') {
          this.mychoiceD = this.mychoiceD ? '' : '1'
        }
        if (e === '5') {
          this.mychoiceE = this.mychoiceE ? '' : '1'
        }
      }
    },
    submit () {
      const localquestion = JSON.parse(localStorage.getItem('question'))
      const acorrectChoice = localquestion.acorrect.toString()
      const bcorrectChoice = localquestion.bcorrect.toString()
      const ccorrectChoice = localquestion.ccorrect.toString()
      const dcorrectChoice = localquestion.dcorrect.toString()
      const ecorrectChoice = localquestion.ecorrect.toString()
      if (this.mychoiceA === '' && this.mychoiceB === '' && this.mychoiceC === '' && this.mychoiceD === '' && this.mychoiceE === '') {
        api.$msg('您还没有选择答案呢')
        return
      }
      if ((this.mychoiceA !== '1' && acorrectChoice === '1') || (this.mychoiceA === '1' && acorrectChoice !== '1')) {
        this.correct = false
      }
      if ((this.mychoiceB !== '1' && bcorrectChoice === '1') || (this.mychoiceB === '1' && bcorrectChoice !== '1')) {
        this.correct = false
      }
      if ((this.mychoiceC !== '1' && ccorrectChoice === '1') || (this.mychoiceC === '1' && ccorrectChoice !== '1')) {
        this.correct = false
      }
      if ((this.mychoiceD !== '1' && dcorrectChoice === '1') || (this.mychoiceD === '1' && dcorrectChoice !== '1')) {
        this.correct = false
      }
      if ((this.mychoiceE !== '1' && ecorrectChoice === '1') || (this.mychoiceE === '1' && ecorrectChoice !== '1')) {
        this.correct = false
      }

      if (this.correct) {
        this.successive++
        var msg = '不错，答案正确!!! '
        if (this.successive > 2) {
          msg += this.successive + ' 连对'
        }
        api.$msg(msg)
      } else {
        if (this.successive >= 0) this.successive = -1
        else this.successive--
        this.answerShow = true
        this.disabled = true
      }
      // 更新个人测试表 studyinfo
      // updateStudyInfo: id  uid  pointid courseid  objid  type  successive studytime
      this.objid = this.question.id

      // 更新数据库
      this.studyInfo = {
        uid: this.uid,
        pointid: this.pointid,
        courseid: this.courseid,
        objid: this.objid,
        type: this.type,
        successive: this.successive,
        correct: this.correct,
        studytime: Date.parse(new Date()) / 1000
      }
      this.$store.dispatch('updateStudyInfo', this.studyInfo)
      // 保存到local
      //   var localstudyinfo = JSON.parse(localStorage.getItem('studyInfo'))
      //   if (localstudyinfo) {
      //     localstudyinfo.push(this.studyInfo)
      //   } else {
      //     localstudyinfo = this.studyInfo
      //   }
      //   localStorage.setItem('studyInfo', JSON.stringify(localstudyinfo))

      if (this.correct) {
        this.$store.dispatch('updateScore', {
          uid: this.uid,
          score: this.question.score,
          field: 'wisdom'
        })

        // 下一题
        this.$store.commit('getQuestionInfoMutation', '')
        this.answerShow = false
        this.disabled = false
        this.radio = ''
        this.$store.dispatch('getDoneInfo', {
          uid: this.uid,
          TestPointid: this.pointid,
          getType: 'ONLY',
          courseid: 0,
          key: Math.random()
        })
      }
    },
    next () {
      // 下一道试题
      this.answerShow = false
      this.disabled = false
      this.radio = ''
      this.$store.commit('getQuestionInfoMutation', '')
      this.$store.dispatch('getDoneInfo', {
        uid: this.uid,
        TestPointid: this.pointid,
        getType: 'ONLY',
        courseid: 0,
        key: Math.random()
      })
    }
  },
  computed: {
    uid () {
      return localStorage.getItem('uid')
    },
    pointid () {
      return localStorage.getItem('TestPointid')
    },
    courseid () {
      return localStorage.getItem('StudyCourseId')
    }

  }
}

</script>

<style lang="scss" scoped>
.question {
  padding: 1.389vw;
  background-color: rgb(243, 233, 217);
}
.question-type {
  color: #555;
  font-size: 4.167vw;
  padding-left: 1.389vw;
}
.question-title {
  color: #111;
  font-size: 4.167vw;
  line-height: 8.333vw;
  padding: 2.778vw;
  padding-top: 3.778vw;
}
.question-item {
  padding-left: 2.778vw;
}
::v-deep .van-checkbox__label {
  line-height: 8.333vw;
  color: rgb(90, 102, 56);
  padding-top: 2.778vw;
  font-size: 4.167vw;
}
::v-deep .van-checkbox__icon {
  padding-top: 2.778vw;
}
.answer {
  background-color: rgb(197, 230, 245);
  padding: 1.389vw;
  font-size: 4.167vw;
}
.answer-title {
  line-height: 8.333vw;
  color: rgb(66, 107, 109);
}
.answer-content {
  color: cadetblue;
  line-height: 5.556vw;
}
.correct {
  color: rgb(144, 218, 247);
  font-size: 3.056vw;
}
.wrong {
  color: red;
  font-size: 3.056vw;
}
.right {
  color: green;
  font-size: 3.056vw;
}
.wait {
  margin: 2.778vw auto;
  font-size: 3.611vw;
  color: coral;
  width: 55.556vw;
}
</style>
