<template>
  <div>
    <div class="testtitle">
      {{ Testpname
      }}<span class="btn" @click="$router.push('/course')">返回课程</span>
    </div>
    <div class="questionnum">
      单选题：{{ pointQuestionNum.numofsingle }} / 多选题：{{
        pointQuestionNum.numofmulti
      }}
      / 主观题：{{ pointQuestionNum.numofsubjective }}
    </div>

    <div v-if="doneInfo.first" class="firststudy">
      <b>这是您第一次学习本节, 系统自动为您推荐试题，欢迎开启您的学习之旅。</b>
    </div>

    <div class="shitiinfo" v-if="!this.$store.state.study.AllOk">
      以下是系统自动推荐试题
    </div>
    <div class="shitiinfoDone" v-else>
      您已学完本节所有试题，可以去测试看看!!
      <van-button
        type="info"
        size="small"
        block
        @click="toTestinfo(TestPointid, Testpname)"
        >去测试</van-button
      >
    </div>
    <Question
      :question="this.$store.state.study.question"
      :type="this.doneInfo.type"
    ></Question>
  </div>
</template>

<script>

import Question from '@/components/course/Question'

export default {
  props: ['doneInfo', 'pointQuestionNum'],
  components: {
    Question: Question
  },
  data () {
    return {
      Testpname: ''
    }
  },
  mounted () {
    this.Testpname = localStorage.getItem('Testpname')
    // this.TestPointid = localStorage.getItem('TestPointid')
  },
  computed: {
    question () {
      return this.$store.state.study.question
    },
    TestPointid: {
      get () {
        return localStorage.getItem('TestPointid')
      },
      set () {

      }
    }
  },
  methods: {
    toTestinfo (id, pname) {
      localStorage.setItem('TestPointid', id)
      localStorage.setItem('Testpname', pname)
      this.$router.push('/Testinfo')
    }
  }
}
</script>

<style lang="scss" scoped>
.testtitle,
.questionnum,
.shitiinfo {
  color: gray;
  font-size: 3.611vw;
  padding: 1.389vw;
}
.firststudy {
  color: green;
  font-size: 3.611vw;
  padding-left: 1.389vw;
}
.shitiinfoDone {
  color: lawngreen;
  font-size: 3.611vw;
  padding: 2.778vw;
  line-height: 8.333vw;
}
.btn {
  background-color: #f5ebd0;
  //   display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #f0960f;
  padding: 0.833vw 0.556vw;
  font-size: 3.611vw;
}
</style>
