import { render, staticRenderFns } from "./Subjective.vue?vue&type=template&id=22373792&scoped=true&"
import script from "./Subjective.vue?vue&type=script&lang=js&"
export * from "./Subjective.vue?vue&type=script&lang=js&"
import style0 from "./Subjective.vue?vue&type=style&index=0&id=22373792&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22373792",
  null
  
)

export default component.exports