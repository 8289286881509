<template>
  <div>
    <NavbarCommon :title="title" :back="back"></NavbarCommon>
    <PointTest
      :doneInfo="this.$store.state.study.doneInfo"
      :pointQuestionNum="this.$store.state.study.pointQuestionNum"
      v-if="
        this.$store.state.study.pointQuestionNum.numofsingle +
          this.$store.state.study.pointQuestionNum.numofmulti +
          this.$store.state.study.pointQuestionNum.numofsubjective >
        0
      "
    ></PointTest>
    <div v-else>
      <van-notice-bar left-icon="volume-o" text="暂无试题..." />
      <div class="btn" @click="$router.push('/course')">返回课程</div>
    </div>
  </div>
</template>

<script>
import NavbarCommon from '@/components/common/NavbarCommon'
import PointTest from '@/components/course/PointTest'
export default ({
  data () {
    return {
      title: '',
      getNumOfPoint: true,
      back: 'pointinfo'
    }
  },
  components: {
    NavbarCommon, PointTest
  },
  beforeMount () {
    this.$store.commit('hideShowTabbar', false)
  },
  mounted () {
    this.title = localStorage.getItem('Testpname')
    this.uid = localStorage.getItem('uid')
    this.TestPointid = localStorage.getItem('TestPointid')
    // this.question = localStorage.getItem('question')
    // 试题总量
    if ((this.$store.state.study.pointQuestionNum.pointid !== this.TestPointid) && this.getNumOfPoint) {
      this.getNumOfPoint = false
      //   this.$store.commit('getNumOfQuestionsMutation', '')
      this.$store.dispatch('getNumOfQuestions', {
        uid: this.uid,
        TestPointid: this.TestPointid,
        getType: 'ONLY',
        courseid: 0
      })
    }
    // 要等上面执行完毕 才能执行下面的
    // 没有试题则不抽取 下面会导致第一次不请求试题
    //  if (this.$store.state.study.pointQuestionNum.numofsingle +
    //       this.$store.state.study.pointQuestionNum.numofmulti +
    //       this.$store.state.study.pointQuestionNum.numofsubjective >
    //       0) {
    // 学习信息及测试情况 推荐试题
    const doneInfo = JSON.parse(localStorage.getItem('doneInfo'))
    const question = JSON.parse(localStorage.getItem('question'))
    var pointid = ''
    if (doneInfo) { pointid = doneInfo.pointid.toString() }
    if (doneInfo && pointid === this.TestPointid.toString()) {
      this.$store.commit('getDoneInfoMutation', doneInfo)
      this.$store.commit('getQuestionInfoMutation', question)
      // if(doneInfo.)
      console.log(doneInfo, 'doneInfo')
      console.log(question, 'question')
    } else {
      console.log('again')
      this.$store.dispatch('getDoneInfo', {
        uid: this.uid,
        TestPointid: this.TestPointid,
        getType: 'ONLY',
        courseid: 0
      })
    }
    // }88
  },
  computed: {
    getPaperInfo () {
      let uidPaperInfo = this.$store.state.study.uidPaperInfo
      if (uidPaperInfo.length > 0) {
        return uidPaperInfo
      } else {
        uidPaperInfo = localStorage.getItem('uidPaperInfo')
      }
      var uidPaperInfoArr = []
      if (uidPaperInfo !== null) {
        uidPaperInfoArr = JSON.parse(uidPaperInfo)
      }
      return uidPaperInfoArr
    }
  },
  beforeUnmount () {
    this.$store.commit('hideShowTabbar', true)
  }
})
</script>

<style lang="scss" scoped>
.btn {
  background-color: #ffffff;
  //   display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #dad5ce;
  padding: 0.833vw 0.556vw;
  font-size: 3.611vw;
}
</style>
